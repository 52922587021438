<template>
  <div>
    <b-loading v-model="isLoading"> </b-loading>
    <client-nav></client-nav>
    <div class="section container">
      <div class="columns">
<user-nav></user-nav>
        <div class="column">
          <div class="container">
            <h1 class="title is-2 has-text-primary has-text-centered">
              Twój wirtualny garaż
            </h1>
            <div v-if="hasCars">
              <div class="is-flex is-flex-wrap-wrap">
                <div
                  class="m-1"
                  v-for="(car, index) in cars"
                  :key="`car-${index}`"
                >
                  <b-button
                    :type="carSelectionArray[index] ? 'is-primary' : ''"
                    @click="selectCar(index, car.id)"
                    rounded
                  >
                    {{ index + 1 }}.
                    {{ car.car_details.serie.model.brand.name }}
                    {{ car.car_details.serie.model.name }}
                  </b-button>
                </div>
              </div>
            </div>
            <div v-else>Brak pojazdów do wyświetlenia</div>
            <hr />
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientNav from "@/components/ClientNav";
import UserNav from '../../components/common/UserNav.vue';

export default {
  components: {
    "client-nav": ClientNav,
    "user-nav": UserNav,
  },

  data() {
    return {
      isLoading: false,
      carSelectionArray: [],
    };
  },

  created() {
    this.isLoading = true;
    this.$store
      .dispatch("clientCars/getCars")
      .then((response) => {
        response;
        /* Wyświetl informacje o niepowodzeniu pobrania danych */
        //console.log(response);
      })
      .catch((error) => {
        console.error(error);
        /* Wyświetl informacje o niepowodzeniu pobrania danych */
        //console.log(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

  methods: {
    selectCar(index, carId) {
      this.carSelectionArray = this.carSelectionArray.map(() => false);
      this.carSelectionArray[index] = true;
      this.$store.dispatch("clientCars/getCarDetails", carId);
      this.$router.push({ name: "Car", params: { id: carId } });
    },
  },

  computed: {
    cars() {
      return this.$store.getters["clientCars/cars"];
    },

    hasCars() {
      return this.cars && this.cars.length > 0;
    },
  },
};
</script>